import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import axios from 'axios';

import { setCredentials, startRecording, stopRecording } from 'uxreplay';

import {
  MmrxButton,
  Form,
  FormSelect,
  Paragraph,
  FormText,
  MmrxSnackbar,
  Alert
} from 'shared-web-components';

import SEO from '../components/seo';
import Footer from '../components/Footer';
import Logo from '../assets/logoImage.png';

// markup
export default function IndexPage() {

  const isWindow = typeof window !== "undefined" && window;

  useEffect(() => {
    if (isWindow) {
      setCredentials('7g9RufgtGvTeqY50E1cHNalbxvg2', 'ae7c6391-1b47-4c4b-94f0-97942a5dc1ac');
      startRecording(3000);
    }
    return () => {
      if (isWindow) {
        stopRecording();
      }
    }
  }, [isWindow]);

  const questions = [
  'I have hot flashes or night sweats.',
  'I have difficulty falling asleep or staying asleep.',
  'I have heart palpitations or a sense of butterflies in my chest.',
  'I feel like my skin is crawling or itching and dry.',
  'I feel more tired than usual.',
  'I have difficulty concentrating or brain fog. ',
  'I am more irritable than usual.',
  'I feel more anxious than usual.',
  'I have more depressed moods.',
  'I have mood swings or crying spells.',
  'I have headaches.',
  'I need to urinate more frequently. ',
  'I have frequent urinary tract infections.',
  'My vagina is dry. ',
  'I have pain during intercourse.',
  'I have decreased desire or interest in sex.',
  'I have difficulty achieving orgasm.',
  'My stomach feels bloated or like I have gained weight. ',
  'I have joint pain/stiffness.',
  'My hair is thinning, I have hair loss or I have new facial hair.',
];

  const [scores, setScores] = useState(Array(questions.length).fill(undefined));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [isCalculating, setIsCalculating] = useState(false);
  

  function handleNewScore(idx, val) {
    let s = [...scores];
    s[idx] = val;
    setScores(s);
  }

  function handleCalculateScore() {
    setIsDialogOpen(true);
  }

  

  console.log('questions', questions);

  let finalScore = scores.reduce((accum, currentVal) => {
    if (currentVal === 'Mild') {
      return accum + 1;
    } else if (currentVal === 'Moderate') {
      return accum + 2;
    } else if (currentVal === 'Severe') {
      return accum + 3;
    }
    return accum;
  }, 0);


  return (
    <div className="score">
      <SEO title="Home" description="Find out your menopause score by taking our free quiz. By MyMenopauseRx" />
      <div className="heading">
        <div style={{ display: 'flex', alignContent: 'center'}}>
          <div className="container-padding">
            <img src={Logo} height="150px" width="150px" />
            <h3>MyMenopauseRx</h3>
            <h1>Should you suffer through menopause or get treatment?</h1>
            <Paragraph style={{ fontSize: 20}}>Find out your menopause score by taking our free quiz.</Paragraph>
          </div>
        </div>
      </div>
        <Container className="container-padding">
            { questions.map((question, idx) => {
              return (
                <div key={idx} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                  <Grid container className="question">
                    <h3 className="question-number">{ idx + 1 }</h3>
                    <div className="inner-question">
                      <Form style={{ width: '100%' }}>
                        <Paragraph>{question}</Paragraph>
                        <FormSelect 
                          className="form-width"
                          fullWidth 
                          onChange={(e) => handleNewScore(idx, e.target.value)}
                          value={scores[idx]}
                          options={["None", "Mild", "Moderate", "Severe"]} 
                          label="Choose answer" />
                      </Form>
                    </div>
                  </Grid>
                </div>
              )
            })}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40}}>
              <MmrxButton disabled={!scores.every(elem => elem !== undefined)} onClick={handleCalculateScore} size="large" emphasis="high">Calculate my score</MmrxButton>
            </div>
        </Container>
        <DialogComponent 
          isOpen={isDialogOpen}
          finalScore={finalScore}
          onClose={() => setIsDialogOpen(false)}
        />
        <Footer />
    </div>
  )
}

function DialogComponent(props) {
  const { isOpen, onClose, finalScore } = props;
  const [email, setEmail] = useState('');
  const [buttonState, setButtonState] = useState('');

  function renderSuccess() {
    if (buttonState === 'success') {
      return (
        <div style={{ marginTop: 10 }}>
          <Alert severity="success">Your score is being calculated and should arrive in your inbox soon!</Alert>
        </div>
      )
    }
    return null;
  }

  function renderDialogContent() {
    return (
      <div>
        <DialogContentText>
          To recieve your menopause score and an explanation, please enter your email address.
          { renderSuccess() }
        </DialogContentText>
        <Form>
          <div className="dialog">
            <FormText fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </Form>
      </div>
    )
  }

  async function sendScore() {
    try {
      setButtonState('loading');
      let response = await axios({
        url: 'https://a5fvyaxlie.execute-api.us-east-1.amazonaws.com/dev/send-score',
        method: 'POST',
        data: {
          email: email,
          score: finalScore
        }
      });
      if (response.status === 200) {
        setButtonState('success');
        setTimeout(() => {
          onClose();
        }, 5000);
      } else {
        setButtonState('error');
      }
    } catch (e) {
      console.error('There was an error submitting the score and email', e);
      setButtonState('error');
    } finally {
      setTimeout(() => {
        if (buttonState === 'success') {
          onClose();
        }
        setButtonState('');
      }, 5000);
    }
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  return (
     <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Get results</DialogTitle>
        <DialogContent>
          { renderDialogContent() }
        </DialogContent>
        <DialogActions>
          <MmrxButton onClick={onClose} emphasis="medium">Cancel</MmrxButton>
          <MmrxButton
            disabled={!validateEmail(email)}
            isLoading={buttonState === 'loading'} 
            hasError={buttonState === 'error'}
            hasSuccess={buttonState === 'success'}
            onClick={sendScore} 
            emphasis="high">Get my score</MmrxButton>
        </DialogActions>
        <MmrxSnackbar isOpen={buttonState === 'error'} severity="error">There was an error getting your email and score.  If this error continues, email <em>hello@mymenopauserx.com</em></MmrxSnackbar>
      </Dialog>
  )
}
