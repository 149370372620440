import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

import Logo from '../assets/logoImage.png';

import { Link } from "gatsby"

import { isMobile } from 'react-device-detect';

import {
  LogoText
} from 'shared-web-components';

export default function Footer(props) {

  return (
    <div className="footer" style={{...props.style}}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <div className="footer-logo">
                <img height="150px" alt="MMRx Logo" src={Logo} style={{ marginBottom: 0, marginTop: 40}}/>
                <LogoText style={{ marginTop: isMobile ? 60 : -20}}>MyMenopauseRx</LogoText>
            </div>
          </Grid>
          <Grid item xs={6} sm={2}>
            <h1>Services</h1>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/treatments">Treatments</a>
            </div>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/labs">Labs</a>
            </div>
          </Grid>
          <Grid item xs={6} sm={2}>
            <h1>Resources</h1>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/faq">FAQ</a>
            </div>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/learn">Learn</a>
            </div>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/contact">Community</a>
            </div>
          </Grid>
          <Grid item xs={6} sm={2}>
            <h1>About</h1>
            {/* <div className="padding-top">
              <a href="https://mymenopauserx.com/about">MMRx Team</a>
            </div> */}
            <div className="padding-top">
              <a href="https://mymenopauserx.com/about">Our story</a>
            </div>
            {/* <div className="padding-top">
              <a href="https://mymenopauserx.com/physicians">Physicians</a>
            </div> */}
          </Grid>
          <Grid item xs={6} sm={2}>
            <h1>Legal</h1>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/privacy">Privacy Policy</a>
            </div>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/consent">Consent to Treatment</a>
            </div>
            <div className="padding-top">
              <a href="https://mymenopauserx.com/tos">Terms of Service</a>
            </div>
          </Grid>
          <Grid item xs={6} sm={2}>
            <h1>Connect with us</h1>
            <a href="https://www.facebook.com/mymenopauserx"><FacebookIcon style={{ color: 'white', fontSize: 40}}/></a>
            <a href="https://www.instagram.com/mymenopauserx/"><InstagramIcon style={{ color: 'white', marginLeft: 10, fontSize: 40 }}/></a>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}